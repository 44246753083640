import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from '@/App.vue';
import router from '@/router/router';
import { createI18n } from 'vue-i18n';

import en from '@/locales/en';
import ru from '@/locales/ru';

const messages = {
	en,
	ru,
};

const i18n = createI18n({
	legacy: false,
	locale: 'en',
	fallbackLocale: 'en',
	messages,
});

const app = createApp(App);

(async () => {
	app.use(i18n);
	app.use(createPinia());
	app.use(router);

	app.mount('#app');
})();
