import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import { launchMiddlewareSystem } from './middleware-system';
import type { Component } from 'vue';

declare module 'vue-router' {
	interface RouteMeta {
		layoutName?: string;
		layoutComponent?: Component;
		middleware?: Middleware | Middleware[];
	}
}

const routes: RouteRecordRaw[] = [
	{
		path: '/',
		name: 'index',
		component: () => import('@/views/index.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeResolve(launchMiddlewareSystem);

export default router;
