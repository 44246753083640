export default {
	mainScreen: {
		title: 'Blockchain for asset tokenization',
		dag: 'on DAG technology',
	},
	dagScreen: {
		title: 'DAG technology allows blocks to be validated in parallel, greatly increasing the scalability of the network',
		blockTypes: {
			banknotes: 'Block for financial transactions',
			newspaper: 'Block for smart contracts',
			serverStack: 'Block for data storage and management',
			handThumbUp: 'Block for voting and management',
			arrowPath: 'Block for microtransaction processing',
			link: 'Block for cross-chain transactions',
			key: 'Block for identification and authorization',
			cpuChip: 'Block for machine learning and AI',
			buildingOffice: 'Block for RWA',
			eye: 'Block for oracle',
		},
	},
	modulesScreen: {
		modules: {
			nods: 'NOD Specialization',
			liquidity: 'Single Liquidity Pool',
			nativeTransactions: { one: 'Native',
two: 'transactions' },
			rwa: 'RWA specialization',
			module: 'Modular architecture',
			ai: { one: 'AI',
two: 'scoring' },
			abstraction: { one: 'abstraction',
two: 'account' },
			smart: 'Advanced Smart Contracts',
			dag: 'dag',
			storage: { one: 'Lum',
two: 'storage' },
			gas: { one: 'Simplified',
two: 'gas',
three: 'system' },
			customBlocks: { one: 'Customization',
two: 'blocks' },
			sites: { one: 'Lum',
two: 'sites' },
			privacy: 'Privacy Levels',
		},
	},
	technologyScreen: {
		title: 'Technology',
		subtitle:
			' Unique modular architecture with customization. You can create your own block types that will be chained in the desired order with any type of relationships and unique scenarios. ',
		example: 'For example...',
		blockTypes: {
			budgetDistribution: 'Budget Allocation Module',
			paymentToContractors: 'Payments to Contractors Module',
			dividends: 'Dividends Module',
			portfolioManagement: 'Portfolio Management Module',
			productCard: 'ProductCard Module',
			reporting: 'Reporting Module',
			productRental: 'Product Lease Module',
			taxPayment: 'TaxPayment Module',
		},
	},
};
