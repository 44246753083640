export default {
	mainScreen: {
		title: 'Блокчейн для токенизации активов',
		dag: 'на технологии DAG',
	},
	dagScreen: {
		title: 'Технология DAG позволяет валидировать блоки параллельно, что значительно увеличивает масштабируемость сети',
		blockTypes: {
			banknotes: 'Блок для финансовых транзакций',
			newspaper: 'Блок для смарт-контрактов',
			serverStack: 'Блок для хранения и управления данными',
			handThumbUp: 'Блок для голосования и управления',
			arrowPath: 'Блок для обработки микротранзакций',
			link: 'Блок для кросс-чейн операций',
			key: 'Блок для идентификации и авторизации',
			cpuChip: 'Блок для машинного обучения и ИИ',
			buildingOffice: 'Блок для RWA',
			eye: 'Блок оракула',
		},
	},
	modulesScreen: {
		modules: {
			nods: 'Специализация НОД',
			liquidity: 'Единый пул ликвидности',
			nativeTransactions: { one: 'Нативные',
two: 'транзакции' },
			rwa: 'Специализация на RWA',
			module: 'Модульная архитектура',
			ai: { one: 'AI',
two: 'скоринг' },
			abstraction: { one: 'Абстракция',
two: 'аккаунта' },
			smart: 'Продвинутые Смарт контракты',
			dag: 'dag',
			storage: { one: 'Lum',
two: 'storage' },
			gas: { one: 'Упрощённая',
two: 'газовая',
three: 'система' },
			customBlocks: { one: 'Кастомизация',
two: 'блоков' },
			sites: { one: 'Lum',
two: 'sites' },
			privacy: 'Уровни Приватности',
		},
	},
	technologyScreen: {
		title: 'Технология',
		subtitle:
			' Уникальная модульная архитектура с кастомной настройкой. Можно создавать свои типы блоков, которые будут выстраиваться в цепочку в необходимом порядке с любыми типами взаимосвязей и уникальными сценариями. ',
		example: 'Например...',
		blockTypes: {
			budgetDistribution: 'Модуль Распределения бюджетов',
			paymentToContractors: 'Модуль Оплаты контрагентам',
			dividends: 'Модуль Дивидендов',
			portfolioManagement: 'Модуль Портфельного менеджмента',
			productCard: 'Модуль Карточки продукта',
			reporting: 'Модуль Отчетности',
			productRental: 'Модуль Аренды продукта',
			taxPayment: 'Модуль Оплаты налогов',
		},
	},
};
